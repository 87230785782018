<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row mb-4">
        <div class="col-12 text-center" v-if="loading">
            <div class="d-block text-center my-5" >
                <div class="spinner-border mb-2" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div>
                Loading..
                </div>
            </div>
        </div>
        <div class="col-12 text-center" v-if="!loading && !cart_list.length && !inactive_list.length">
            <div class="py-5 rounded-3 text-dark">
            <i class="bi bi-cart4 display-4 text-primary mb-3"></i>
            <h2 class="mb-2 fw-medium">Your cart is empty!</h2>
            <p class="text-muted">Looks like you haven't made any choice yet.</p>
            </div>
        </div>
      
        <div class="col-12 col-lg-8"> 
            <div class="table-responsive rounded-3 shadow-none border mb-3 bg-white" v-if="cart_list.length">
            <table class="table m-0 align-middle min-width-760">
            <thead class="border-primary">
                <tr class="bg-primary border-primary text-white fw-medium">
                <th class="text-center">
                <div class="form-check px-0 ps-3">
                    <input class="form-check-input border-muted mx-0 font-size-16" type="checkbox" v-model="allItemtoCheckoutStatus"  :id="`cart_000`" @change="selectAllItemtoCheckout">
                    <label class="form-check-label  p-0 m-0" :for="`cart_000`">
                    </label>
                  </div>
                </th>
                <th width="80" class="text-center">Images</th>
                <th scope="col"  >Product Name</th>
                <th scope="col" class="text-center text-nowrap">Unit Price</th>
                <th scope="col" class="text-center">Quantity</th>
                <th scope="col" class="text-center text-nowrap">Line Total</th>
                <th scope="col" class="text-center"></th>
                </tr>
            </thead>
            <tbody>
                
                <tr v-for="(item, index) in cart_list" :key="index">
                <td class="text-center" width="50">
                    <div class="form-check px-0 ps-3">
                      <input class="form-check-input border-muted mx-0 font-size-16" type="checkbox" v-model="selected2Checkout" :value="item.cart_id"  :id="`cart_${index}`" @change="updateShipping('MY')">
                      <label class="form-check-label p-0 m-0" :for="`cart_${index}`">
                      </label>
                    </div>
                </td>
                <td class="text-center">
                  <div class="bg-white rounded-3 border">
                    <img :src="item.image" class=" rounded-3" height="65" alt="">
                  </div>
                </td>
                <td>
                    <strong class="d-block fw-medium font-size-15 text-nowrap">{{item.product_name}}</strong>
                    <small class="text-muted d-block font-size-14" v-if="item.product_variant !==''">{{item.product_variant}} </small>
                </td>
                <td class="text-center c_product_price text-nowrap">{{item.currency_code}} {{item.unit_price}}</td>
                <td class="text-nowrap text-center">
                        <!-- <div class="input-group product-touchspin d-inline-block" style="width:80px">
                            <span class="input-group-btn input-group-prepend">
                                <div class="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                                    <span class="input-group-btn input-group-prepend"  @click="decrement(index)">
                                        <button class="btn btn-primary bootstrap-touchspin-down" :disabled="updateCartLoading" type="button">&#8722;</button>
                                    </span>
                                   <div class="c_quantity">{{item.quantity}}</div>
                                    <span class="input-group-btn input-group-append" @click="increment(index)">
                                        <button class="btn btn-primary bootstrap-touchspin-up" :disabled="updateCartLoading|| (item.current_product_stock<=item.quantity)" type="button">&#43;</button>
                                    </span>
                                </div>
                            </span>
                        </div> -->
                        <div class="text-center">
                          <div class="d-flex justify-content-center align-items-center mb-0">
                            <button class="btn btn-primary text-white p-1 py-0 btn-sm" @click="decrement(index)" :disabled="updateCartLoading"><i class="bi bi-dash font-size-15 lh-0"></i></button>
                            <div class="c_quantity">{{item.quantity}}</div>
                            <button class="btn btn-primary p-1 py-0 btn-sm" @click="increment(index)" :disabled="updateCartLoading|| (item.current_product_stock<=item.quantity)"><i class="bi bi-plus font-size-15 lh-0"></i></button>
                          </div>
                        </div>
                        
                        <div><span class="badge text-center font-weight-bold text-danger" v-if="quantityOverStock[index]==true"> {{item.current_product_stock}} Items Left. <br>Please Update Qty.</span>
                        </div>

                </td>
                <td class="text-nowrap text-center c_product_price">{{item.currency_code}} {{item.line_total}}</td>
                <td class="text-nowrap text-center c_action"><button class="btn font-size-18" :disabled="removeLoading"><i class="bi bi-x-circle text-secondary" @click="removeItem(item)"></i></button> </td>
                </tr>

                
                <tr v-if="!cart_list.length && !loading">
                    <td colspan="6" height="200" class="text-center"><strong class="d-block mb-2">Your cart is empty!</strong><p class="text-muted">Looks like you haven't made any choice yet.</p></td>
                </tr>
                <tr v-if="loading">
                    <td colspan="6" class="bg-white">
                        <div class="d-block text-center my-5" >
                        <div class="spinner-border spinner-border2 text-muted mb-2" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div>
                        Loading..
                        </div>
                        </div>
                    </td>
                </tr>
                
            </tbody>
            </table>
            </div>
            
            <div class="table-responsive rounded-3 position-relative border mb-3" v-if="inactive_list.length">
            <div class="inactive_overlay"></div>
            <table class="table m-0 align-middle min-width-760">
              <thead class="border-primary">
                <tr class="bg-primary border-primary text-white fw-medium">
                  <th colspan="6" class="text-center">Inactive List</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in inactive_list" :key="index">
                <td class="text-center" width="80" style="min-width: 80px;">
                  <div class="bg-white rounded-3 border"><img :src="item.image" class="img-fluid rounded-3" width="80" alt=""></div>
                </td>
                <td class="c_product_name text-left">
                  <strong class="d-block fw-medium font-size-14 text-nowrap">{{item.product_name}}</strong>
                  <small class="text-muted d-block" v-if="item.product_variant !==''">Available in: {{item.product_variant}} </small>
                    <span class="badge bg-danger font-size-11 mt-2" v-if="item.current_product_stock==0">Sold Out</span> 
                    <span class="badge bg-danger font-size-11 mt-2" v-if="item.productDelete==true">Deleted</span>
                </td>
                <td class="text-center c_product_price text-nowrap">{{item.currency_code}} {{item.unit_price}}</td>
                <td class="text-nowrap text-center" width="120">
                     <div class="c_quantity text-center w-100 mb-0">{{item.quantity}}</div>
                     <span class="text-center font-size-12 text-danger"> {{item.current_product_stock}} Items Left</span>
                </td>
                <td class="text-nowrap text-center c_product_price">{{item.currency_code}} {{item.line_total}}</td>
                <td class="text-nowrap text-center c_action">
                  <button class="btn font-size-18 position-relative" style="z-index: 3;" :disabled="removeLoading"><i class="bi bi-x-circle text-secondary" @click="removeItem2(item)"></i></button> 
                </td>
                </tr>
                
            </tbody>
            </table>
            </div>
        </div>
        <div class="col-12 col-lg-4" v-if="cart_list.length" >
            <div class="card border rounded-3 p-xl-4 p-3 shadow-none">
              <div class="inactive_overlay text-center" v-if="updateCartLoading || loading">
                  <div class="spinner-border spinner-border2 text-muted mb-2" role="status">
                      <span class="sr-only">Loading...</span>
                  </div>
              </div>
              <h4 class="text-primary mb-3 pb-2 border-bottom border-primary border-2">Order Summary</h4>
              <table class="align-middle">
                  <tr>
                      <td><label>Subtotal</label></td>
                      <td class="text-end"><span>{{summaryDetail.currency_code}} {{summaryDetail.sub_total || '0.00'}}</span></td>
                  </tr>
                  <tr v-if="summaryDetail.shipping_fee">
                      <td><label>Shipping Fee <span class="text-muted fw-normal">({{summaryDetail.shipping_type}})</span></label></td>
                      <td class="text-end"><span>{{summaryDetail.currency_code}} {{summaryDetail.shipping_fee}}</span></td>
                  </tr>
                  <tr v-if="summaryDetail.coupon_name">
                      <td><label>Coupon <span class="d-block fw-normal text-muted">[{{summaryDetail.coupon_name}}]</span></label></td>
                      <td class="text-end"><span>-{{summaryDetail.currency_code}} {{summaryDetail.discount_coupon}}</span></td>
                  </tr>
                  <!-- <tr>
                      <td><input type="text" class="form-control" placeholder="Discount Coupon" v-model="applyCouponCode" :disabled="!selected2Checkout.length || alreadyApplyCoupon"></td>
                      <td>
                      <button v-if="!alreadyApplyCoupon" class="btn btn-theme mt-0 w-100" @click="applyCoupon" :disabled="applyLoading || updateCartLoading || loading || !selected2Checkout.length" >Apply</button>
                      <button v-else class="btn btn-theme mt-0 w-100" @click="removeCoupon" :disabled="applyLoading || updateCartLoading || loading || !selected2Checkout.length " >Remove</button></td>
                  </tr> -->
                  <tr>
                      <td><label class="fw-medium text-dark">Total</label></td>
                      <td class=" text-end"><span class="fw-medium text-dark">{{summaryDetail.currency_code}} {{summaryDetail.total_amount || '0.00'}}</span></td>
                  </tr>
              </table>

              <button @click="processToCheckOutPage()" type="button" :disabled="updateCartLoading || loading || !selected2Checkout.length" class="btn btn-primary btn-lg flex-fill w-100 font-size-16">
                <i class="bi bi-bag-check me-1"></i> Proceed to Checkout 
                <span class="spinner-border spinner-border-sm ms-1" v-if="buttonLoading[0]" role="status"
                aria-hidden="true"></span>
              </button>
            </div>
        </div>
    </div>
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
    <productCartnFav ref="modalFunc2" :data="{accessUsername:accessUsername, assessToken:accessToken, updateData:updateData}" @callParentFunction="fromChildFunction"  @callParentFunction2="fromChildFunction2" @callParentFunction3="fromChildFunction3"/>
  </Layout>
</template>

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
//import DatePicker from "vue2-datepicker";
import productCartnFav from "@/views/pages/app/modal/productCartnFav";
/**
 * Page
 */
const PageTitle = "Shopping Cart"
const testToken = "17429567196cf23d4c26c61c49cbb1bac38b350b15"
const testUser = "weijey@outlook.com"

const CancelToken = axios.CancelToken;
let cancel;

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
   //DatePicker,
    productCartnFav
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      currency: appConfig.currency,
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      submitted:false,
      loading:false,
      updateCartLoading:false,
      removeLoading:false,
      pageLoading:false,
      buttonLoading:[false,false,false],
      currentPage: 1,
      seletedMethod: "",
      sortBy:"",
      typeOfLetter:"",
      searchKeyword: "",
      searchMerchantItemId:"",
      filterType:"created_date",
      searchAmount:"",
      perPage: 20,
      totalData: 0,
      cart_list:[],
      inactive_list:[],
      summaryDetail:{
          sub_total:"",
          currency_code:"",
          shipping_type:"",
          shipping_fee:"",
          coupon_name:"",
          coupon_type:"",//fixed or percent
          coupon_value:"",
          discount_coupon:"",
          total_amount:0.00
      },
      quantityOverStock:[],
      quantityOverStock2:[],
      selected2Checkout: [],
      allItemtoCheckoutStatus:false,

   
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled:false,
      shortcuts:[],
      create_modify_permission:false,
      updateData:{},
    };
  },
  middleware: "authentication",
  async mounted(){
    this.reload();
    this.title = PageTitle
    this.items[1].text = PageTitle
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    this.date_range = this.$refs.commonFunc.last30Days()
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut()
    this.getCartList();
    // this.sortByQuery();
    // await this.handlePageChange(1);
    
  }, 
  created(){
    
  },
  computed: {
    currentRouteId() {
      return this.$route.params.id; // Use params if your route uses params
    },
    currentRouteName() {
      return this.$route.params.name; // Use params if your route uses params
    }
  },
  methods:{
    increment(index) {
      if (this.cart_list[index].quantity < parseInt(this.cart_list[index].current_product_stock)) {
        this.cart_list[index].quantity++
        this.updateCart(index)
      }
    },
    decrement(index) {
      if (this.cart_list[index].quantity === 0) {
        this.cart_list[index].quantity = 1
      }
      if (this.cart_list[index].quantity > 1) {
        this.cart_list[index].quantity--
        this.updateCart(index)
      }
    },
    updateCart(index) {
      if (cancel !== undefined) {
        cancel()
        this.updateCartLoading = false
      }
      this.$Progress.start();
      this.updateCartLoading = true
      var bodyFormData = new FormData();
      bodyFormData.append("email", testUser);
      bodyFormData.append("accessToken", testToken);
      bodyFormData.append("cartID", this.cart_list[index].cart_id);
      bodyFormData.append("qty", this.cart_list[index].quantity);
      axios({
        method: "post",
        url: appConfig.WebsiteHost + 'controller/product/updateToCart',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
        cancelToken: new CancelToken(c => cancel = c),
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData)

            this.cart_list = [];
            this.inactive_list = []

            var data = resData.cart_list;
            var returnData = []
            var inactiveData = []
            data.forEach((value) => {
              var line_total = this.lineTotal(parseFloat(value.unit_price), parseInt(value.quantity))
              if (value.isInactiveList == false) {
                returnData.push({
                  cart_id: value.cart_id,
                  image: value.image,
                  product_id: value.product_id,
                  variant_id: value.variant_id,
                  product_name: value.product_name,
                  product_variant: value.product_variant,
                  quantity: value.quantity,
                  current_product_stock: value.current_product_stock,
                  unit_price: parseFloat(value.unit_price).toFixed(2),
                  line_total: line_total,
                  currency_code: value.currency_code,
                  isInactiveList: value.isInactiveList,
                  productDelete: value.productDelete,
                  isBread: value.is_bread,
                })
              } else if (value.isInactiveList == true) {
                inactiveData.push({
                  cart_id: value.cart_id,
                  image: value.image,
                  product_id: value.product_id,
                  variant_id: value.variant_id,
                  product_name: value.product_name,
                  product_variant: value.product_variant,
                  quantity: value.quantity,
                  current_product_stock: value.current_product_stock,
                  unit_price: parseFloat(value.unit_price).toFixed(2),
                  line_total: line_total,
                  currency_code: value.currency_code,
                  isInactiveList: value.isInactiveList,
                  productDelete: value.productDelete,
                  isBread: value.is_bread,
                })
              }

            });
            //remove id from selected2Checkout when product go inactive
            inactiveData.forEach(element => {
              let index = this.selected2Checkout.findIndex(obj => obj === element.cart_id);
              this.selected2Checkout.splice(index, 1);
            });
            //set data
            this.cart_list = returnData;
            this.inactive_list = inactiveData

            this.updateShipping('MY')
          }
          else if (resData.status == 440) {
            Swal.fire(
              {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          }
          else {
            Swal.fire(
              {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.updateCartLoading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.updateCartLoading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
    },
    removeItem(item) {
      if (cancel !== undefined) {
        cancel()
        this.removeLoading = false
      }
      this.$Progress.start();
      this.removeLoading = true
      var bodyFormData = new FormData();
      bodyFormData.append("email", testUser);
      bodyFormData.append("accessToken", testToken);
      bodyFormData.append("cartID", item.cart_id);
      axios({
        method: "post",
        url: appConfig.WebsiteHost + 'controller/product/removeCartProduct',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
        cancelToken: new CancelToken(c => cancel = c),
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData)

            let index = this.selected2Checkout.findIndex(obj => obj === item.cart_id);
            this.selected2Checkout.splice(index, 1);

            this.cart_list = [];
            this.inactive_list = []

            var data = resData.cart_list;
            var returnData = []
            var inactiveData = []
            data.forEach((value) => {
              var line_total = this.lineTotal(parseFloat(value.unit_price), parseInt(value.quantity))
              if (value.isInactiveList == false) {
                returnData.push({
                  cart_id: value.cart_id,
                  image: value.image,
                  product_id: value.product_id,
                  variant_id: value.variant_id,
                  product_name: value.product_name,
                  product_variant: value.product_variant,
                  quantity: value.quantity,
                  current_product_stock: value.current_product_stock,
                  unit_price: parseFloat(value.unit_price).toFixed(2),
                  line_total: line_total,
                  currency_code: value.currency_code,
                  isInactiveList: value.isInactiveList,
                  productDelete: value.productDelete,
                  isBread: value.is_bread,
                })
              } else if (value.isInactiveList == true) {
                inactiveData.push({
                  cart_id: value.cart_id,
                  image: value.image,
                  product_id: value.product_id,
                  variant_id: value.variant_id,
                  product_name: value.product_name,
                  product_variant: value.product_variant,
                  quantity: value.quantity,
                  current_product_stock: value.current_product_stock,
                  unit_price: parseFloat(value.unit_price).toFixed(2),
                  line_total: line_total,
                  currency_code: value.currency_code,
                  isInactiveList: value.isInactiveList,
                  productDelete: value.productDelete,
                  isBread: value.is_bread,
                })
              }

            });
            //remove id from selected2Checkout when product go inactive
            inactiveData.forEach(element => {
              let index = this.selected2Checkout.findIndex(obj => obj === element.cart_id);
              this.selected2Checkout.splice(index, 1);
            });
            //set data
            this.cart_list = returnData;
            this.inactive_list = inactiveData

            this.updateShipping('MY')
          }
          else if (resData.status == 440) {
            Swal.fire(
              {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          }
          else {
            Swal.fire(
              {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.removeLoading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.removeLoading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
    },
    removeItem2(item) {
      if (cancel !== undefined) {
        cancel()
        this.removeLoading = false
      }
      this.$Progress.start();
      this.removeLoading = true
      var bodyFormData = new FormData();
      bodyFormData.append("email", testUser);
      bodyFormData.append("accessToken", testToken);
      bodyFormData.append("cartID", item.cart_id);
      axios({
        method: "post",
        url: appConfig.WebsiteHost + 'controller/product/removeCartProduct',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
        cancelToken: new CancelToken(c => cancel = c),
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData)

            let index = this.selected2Checkout.findIndex(obj => obj === item.cart_id);
            this.selected2Checkout.splice(index, 1);

            this.cart_list = [];
            this.inactive_list = []

            var data = resData.cart_list;
            var returnData = []
            var inactiveData = []
            data.forEach((value) => {
              var line_total = this.lineTotal(parseFloat(value.unit_price), parseInt(value.quantity))
              if (value.isInactiveList == false) {
                returnData.push({
                  cart_id: value.cart_id,
                  image: value.image,
                  product_id: value.product_id,
                  variant_id: value.variant_id,
                  product_name: value.product_name,
                  product_variant: value.product_variant,
                  quantity: value.quantity,
                  current_product_stock: value.current_product_stock,
                  unit_price: parseFloat(value.unit_price).toFixed(2),
                  line_total: line_total,
                  currency_code: value.currency_code,
                  isInactiveList: value.isInactiveList,
                  productDelete: value.productDelete,
                  isBread: value.is_bread,
                })
              } else if (value.isInactiveList == true) {
                inactiveData.push({
                  cart_id: value.cart_id,
                  image: value.image,
                  product_id: value.product_id,
                  variant_id: value.variant_id,
                  product_name: value.product_name,
                  product_variant: value.product_variant,
                  quantity: value.quantity,
                  current_product_stock: value.current_product_stock,
                  unit_price: parseFloat(value.unit_price).toFixed(2),
                  line_total: line_total,
                  currency_code: value.currency_code,
                  isInactiveList: value.isInactiveList,
                  productDelete: value.productDelete,
                  isBread: value.is_bread,
                })
              }

            });
            //remove id from selected2Checkout when product go inactive
            inactiveData.forEach(element => {
              let index = this.selected2Checkout.findIndex(obj => obj === element.cart_id);
              this.selected2Checkout.splice(index, 1);
            });
            //set data
            this.cart_list = returnData;
            this.inactive_list = inactiveData

            this.updateShipping('MY')
          }
          else if (resData.status == 440) {
            Swal.fire(
              {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          }
          else {
            Swal.fire(
              {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.removeLoading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.removeLoading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
    },
    lineTotal(price, quantity) {
      var total = 0.00
      total = parseFloat(price) * parseInt(quantity)
      total = total.toFixed(2)
      return total
    },
    setCartListnSummary(response) {
      var data = response.cart_list
      var returnData = []
      var inactiveData = []
      data.forEach((value) => {
        var line_total = this.lineTotal(value.unit_price, value.quantity)
        if (value.isInactiveList == false) {
          returnData.push({
            cart_id: value.cart_id,
            image: value.image,
            product_id: value.product_id,
            variant_id: value.variant_id,
            product_name: value.product_name,
            product_variant: value.product_variant,
            quantity: value.quantity,
            current_product_stock: value.current_product_stock,
            unit_price: parseFloat(value.unit_price).toFixed(2),
            line_total: line_total,
            currency_code: value.currency_code,
            isInactiveList: value.isInactiveList,
            productDelete: value.productDelete,
            isBread: value.is_bread,
          })
        } else if (value.isInactiveList == true) {
          inactiveData.push({
            cart_id: value.cart_id,
            image: value.image,
            product_id: value.product_id,
            variant_id: value.variant_id,
            product_name: value.product_name,
            product_variant: value.product_variant,
            quantity: value.quantity,
            current_product_stock: value.current_product_stock,
            unit_price: parseFloat(value.unit_price).toFixed(2),
            line_total: line_total,
            currency_code: value.currency_code,
            isInactiveList: value.isInactiveList,
            productDelete: value.productDelete,
            isBread: value.is_bread,
          })
        }

      });

      //set data
      this.cart_list = returnData;
      this.inactive_list = inactiveData

      var currentUrl = window.location.href;
      if (!data.length) {
        if (currentUrl.indexOf("checkout") > -1) {
          //redirectPage('cart')
          console.log("redirect");
        }
      }

      // this.summaryDetail={
      //     sub_total:"",
      //     currency_code:"MYR",
      //     shipping_type:"Local",
      //     shipping_fee:"0.00",
      //     coupon_name:"Member Discount",
      //     coupon_type:"percent",
      //     coupon_value:"10.00",
      //     discount_coupon:"",
      //     total_amount:0.00
      // }
      this.summaryDetail = response.summaryDetail
      this.payment_method_selected = response.payment_method_selected
      this.bread_shipping_fee = response.summaryDetail.bread_shipping_fee
      //this.updateOrderSummary()

      if (currentUrl.indexOf("checkout") > -1) {
        this.getAddress()
      } else {
        this.updateShipping("MY")
      }
    },
    updateShipping(country) {
      var currentUrl = window.location.href;
      if (country == 'MY') {
        this.summaryDetail.shipping_type = "Local";

        if (currentUrl.indexOf("checkout") > -1) {
          if (this.cart_list.length > 0) {
            let onlyBread = this.cart_list.every(value => value.isBread);
            if (onlyBread) {
              this.summaryDetail.shipping_fee = this.bread_shipping_fee
            } else {
              this.summaryDetail.shipping_fee = "0.00"
            }
          } else {
            this.summaryDetail.shipping_fee = "0.00"
          }
        } else {
          const selectedCart2Checkout = this.cart_list.filter(item => this.selected2Checkout.includes(item.cart_id));
          if (selectedCart2Checkout.length > 0) {
            let onlyBread = selectedCart2Checkout.every(value => value.isBread);
            if (onlyBread) {
              this.summaryDetail.shipping_fee = this.bread_shipping_fee
            } else {
              this.summaryDetail.shipping_fee = "0.00"
            }
          } else {
            this.summaryDetail.shipping_fee = "0.00"
          }
        }


      } else {
        this.summaryDetail.shipping_type = "International";
        this.summaryDetail.shipping_fee = "200.00"
      }
      this.updateOrderSummary()
    },
    updateOrderSummary() {
      var currentUrl = window.location.href;
      var subtotal = 0.00
      var total_qty = 0
      var discount_coupon = 0
      if (currentUrl.indexOf("checkout") > -1) {
        this.cart_list.forEach(value => {
          subtotal += parseFloat(value.line_total)
        });
      } else {
        const selectedCart2Checkout = this.cart_list.filter(item => this.selected2Checkout.includes(item.cart_id));
        selectedCart2Checkout.forEach(value => {
          subtotal += parseFloat(value.line_total)
        });
      }

      this.cart_list.forEach(value => {
        total_qty += parseInt(value.quantity)
      });
      if (this.summaryDetail.coupon_type == 'fixed') {
        discount_coupon = parseFloat(this.summaryDetail.coupon_value)
      } else if (this.summaryDetail.coupon_type == 'percent') {
        const percentage = parseFloat(this.summaryDetail.coupon_value);
        const decimal = percentage / 100
        discount_coupon = subtotal * decimal
        discount_coupon = parseFloat(discount_coupon)
      }
      discount_coupon = discount_coupon.toFixed(2)
      const total_amount = subtotal + parseFloat(this.summaryDetail.shipping_fee) - parseFloat(discount_coupon);
      this.summaryDetail.sub_total = subtotal.toFixed(2)
      this.summaryDetail.discount_coupon = discount_coupon
      this.summaryDetail.total_amount = total_amount.toFixed(2) < 0 ? "0.00" : total_amount.toFixed(2),
        //setCart(parseInt(total_qty))
        console.log(total_qty)

      var updateData = {
        "cart": total_qty,
      };
      this.$refs.commonFunc.updateData(JSON.stringify(updateData));
      this.fromChildFunction3();

      this.checkQuantityOverStock()
    },
    checkQuantityOverStock() {
      this.QuantityOverStock = []
      this.QuantityOverStock2 = []
      this.cart_list.forEach((value, index) => {
        if (parseInt(value.quantity) > parseInt(value.current_product_stock)) {
          this.QuantityOverStock.push(true)
        } else {
          this.QuantityOverStock.push(false)
        }
        console.log(index)
      })
      this.inactive_list.forEach((value, index) => {
        if (parseInt(value.quantity) > parseInt(value.current_product_stock)) {
          this.QuantityOverStock2.push(true)
        } else {
          this.QuantityOverStock2.push(false)
        }
        console.log(index)
      })
      var currentUrl = window.location.href;
      if (currentUrl.indexOf("checkout") > -1) {
        if (this.QuantityOverStock.includes(true)) {
          Swal.fire({
            icon: 'error',
            title: 'Error...',
            text: "Sorry, some of the items have exceeded the available stock.",
            showCancelButton: false,
            confirmButtonColor: '#222',
            cancelButtonColor: '#2222',
            confirmButtonText: 'Back to Shopping Cart'
          }).then(() => {
            // redirectPage('cart')
            // sessionStorage.removeItem("Checkout");
          })
        }
        if (this.QuantityOverStock2.includes(true)) {
          Swal.fire({
            icon: 'error',
            title: 'Error...',
            text: "Some of the items are out of stock or invalid. We apologize for the inconvenience.",
            showCancelButton: false,
            confirmButtonColor: '#222',
            cancelButtonColor: '#2222',
            confirmButtonText: 'Back to Shopping Cart'
          }).then(() => {
            // redirectPage('cart')
            // sessionStorage.removeItem("Checkout");
          })
        }
      }
    },
    selectAllItemtoCheckout() {
      this.selected2Checkout = []
      if (this.allItemtoCheckoutStatus == true) {
        this.cart_list.forEach(element => {
          this.selected2Checkout.push(element.cart_id)
        });
      } else {
        this.selected2Checkout = []
      }
      this.updateShipping('MY')
    },
    sortByQuery() {
      this.$router.replace({ query: { sortBy: this.sortBy } }).catch(() => { });
      this.handlePageChange(1);
    },
    fromChildFunction: function (e) {
      var data = {}
      data = {
        id: e.id,
        category_name: e.title,
      }
      this.navigateToCategory(data);
    },
    fromChildFunction2: function (e) {
      this.sortBy = ''
      this.$router.replace({
        name: 'nova-market',
        query: {
          tag: e.title
        }
      }).catch(() => { });
      this.selectedTag = e.title
      this.handlePageChange(1);
    },
    fromChildFunction3() {
      this.$refs.main.callVerticalandTriggerTopbar();
    },
    navigateToCategory(value) {
      this.sortBy = ''
      this.selectedTag = ''
      this.$router.replace({
        name: 'nova-market with categories',
        params: {
          id: value.id,
          name: value.category_name.replace(' ', '-').toLowerCase()
        }
      }).catch(() => { });
      this.selectedCategory = value.id
      this.handlePageChange(1);
    },
    isCategoryActive(categoryId) {
      return categoryId == this.currentRouteId
    },
    getCartList() {
      this.$Progress.start();
      this.loading = true
      var bodyFormData = new FormData();
      bodyFormData.append("email", testUser);
      bodyFormData.append("accessToken", testToken);
      axios({
        method: "post",
        url: appConfig.WebsiteHost + 'controller/product/getCartList',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData)
            this.cart_list = [];
            this.setCartListnSummary(resData.data)
            this.summaryDetail = resData.data.summaryDetail;
          }
          else if (resData.status == 440) {
            Swal.fire(
              {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          }
          else {
            Swal.fire(
              {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
      }
    },
    notBeforeToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    DateChanged() {
      console.log("datechange" + this.date_range);
      this.handlePageChange(1);
      this.currentPage = 1;
      this.disable_date = true;
    },
    handlePageChange(current_page) {
      this.pageNum = current_page;
      this.currentPage = current_page;
      this.getData(this.pageNum, this.searchKeyword);
      //this.exportData(1)
    },
    search() {
      this.handlePageChange(1);
      this.currentPage = 1;
    },
    exportToExcel(Data, name, type) {
      var title = name.replace(/ /g, "_");
      var ws = window.XLSX.utils.json_to_sheet(Data);
      let objectMaxLength = [];
      for (let i = 0; i < Data.length; i++) {
        let value = Object.values(Data[i]);
        for (let j = 0; j < value.length; j++) {
          if (typeof value[j] == "number") {
            objectMaxLength[j] = 5;
          } else {
            objectMaxLength[j] =
              objectMaxLength[j] >= value[j].length
                ? parseInt(objectMaxLength[j])
                : parseInt(value[j].length) + 5;
          }
        }
      }
      var wscols = []
      objectMaxLength.forEach((value, index) => {
        console.log(value)
        wscols.push({ width: objectMaxLength[index] })
      }),
        ws['!cols'] = wscols;

      var wb = window.XLSX.utils.book_new();
      window.XLSX.utils.book_append_sheet(wb, ws, name)
      window.XLSX.writeFile(wb, title + '.' + (type || 'xlsx'))
    },
    async getData(pages, keyword) {
      this.$Progress.start();
      this.disabled = true;
      this.pageLoading = true;
      this.disable_date = true;
      this.returnData = [];
      var bodyFormData = new FormData();
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("page", pages);
      bodyFormData.append("type", this.typeOfLetter);
      bodyFormData.append("limit", this.perPage);
      bodyFormData.append("keyword", keyword);
      bodyFormData.append("sortBy", this.sortBy);
      bodyFormData.append("filterBy", this.filterType);
      bodyFormData.append("dateStart", this.date_range[0]);
      bodyFormData.append("dateEnd", this.date_range[1]);

      if ('tag' in this.$route.query) {
        if (this.$route.query.tag) {
          this.selectedTag = this.$route.query.tag
        }
      } else {
        this.selectedTag = ""
      }

      if (this.$route.params.id) {
        this.selectedCategory = this.$route.params.id
      } else {
        this.selectedCategory = ""
      }

      axios({
        // method: "post",
        //url: `/api/letter_of_support.json`,
        //url: appConfig.APIHost + 'controller/letterOfSupport/getAllLetterOfSupportList',
        method: "post",
        url: `${appConfig.WebsiteHost}/controller/product/getCartList`,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.returnData = [];
          var resData = response.data;
          this.totalData = resData.total_products;
          var responseData = resData.products;

          if (resData.status == 200) {
            this.returnData = responseData;

            var totalCarts = 0
            responseData.forEach(element => {
              if (element.isInactiveList==false){
                totalCarts =+ element.quantity;
                console.log(totalCarts)
              }
            });
            //newest need add into api
            var updateData = {
              "cart": totalCarts,
            };
            this.$refs.commonFunc.updateData(JSON.stringify(updateData));
            this.fromChildFunction3();
          }
          else if (resData.status == 440) {
            Swal.fire(
              {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          }
          else {
            Swal.fire(
              {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.disabled = false;
          this.pageLoading = false;
          this.disable_date = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.disabled = false;
          this.pageLoading = false;
          this.disable_date = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire(
            {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
    },

    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type//data.account_type;
          const permission = data.modules.filter(e => e.name === 'letter_of_support')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission == 0 ? false : true;
          } else {
            console.log('Module with name "letter_of_support" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>
<style scoped>
.c_quantity{
  width: 50px;
  text-align: center;
}
.inactive_overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}
</style>